export const getSizeAmericanValue = (
  size: string,
  americanValue: string = ""
) => {
  // If the size has an americanValue, return it
  if (americanValue) {
    return americanValue
  }

  const sizeInches = size?.split("x").map((s) => Math.round(Number(s) / 2.54))

  // If one of the sizes is not a number, return the size label
  if (sizeInches.some((s) => isNaN(s))) {
    return size
  }

  // If the size is in correct format, return the size in inches as a string
  return sizeInches.join("x")
}
