// External packages
import * as React from "react"
import { Box, BoxProps, Label, LabelProps } from "theme-ui"

// Components
import { Icon, IconProps } from "./Icon"

type UiRadixCheckboxOwnProps = {
  size?: "sm" | "md"
}

export const UiRadixCheckbox: React.FC<
  React.ComponentPropsWithoutRef<"button"> & BoxProps & UiRadixCheckboxOwnProps
> = React.forwardRef(({ size, disabled, sx, ...rest }, ref) => (
  <Box
    {...rest}
    ref={ref}
    as="button"
    sx={{
      ...sx,
      width: size === "sm" ? [4, 3] : 4,
      height: size === "sm" ? [4, 3] : 4,
      border: "1px solid",
      borderColor: disabled ? "grayscale.300" : "primary",
      backgroundColor:
        rest["data-state"] === "checked" && disabled === true
          ? "grayscale.600"
          : rest["data-state"] === "checked"
          ? "primary"
          : disabled === true
          ? "grayscale.300"
          : "transparent",
      cursor: "pointer",
      transition: "background-color .2s",
      padding: 0,
      marginBlockStart: size === "sm" ? ["1px", 1] : "2px",
    }}
  />
))

type UiRadixCheckboxIconOwnProps = {
  size?: "sm" | "md"
}

export const UiRadixCheckboxIcon: React.FC<
  IconProps & UiRadixCheckboxIconOwnProps
> = React.forwardRef(({ size, ...rest }, ref) => (
  <Icon
    {...rest}
    ref={ref}
    size={size === "sm" ? [4, 3] : 4}
    sx={{
      flexShrink: 0,
      width: size === "sm" ? [4, 3] : 4,
      height: size === "sm" ? [4, 3] : 4,
      color: "grayscale.white",
      opacity: rest["data-state"] === "checked" ? 1 : 0,
      visibility: rest["data-state"] === "checked" ? "visible" : "hidden",
      transition: "opacity .2s, visibility .2s",
      marginBlockStart: "-1px",
      marginInlineStart: "-1px",
    }}
  />
))

UiRadixCheckboxIcon.displayName = "UiRadixCheckboxIcon"

export const UiRadixCheckboxLabel: React.FC<LabelProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Label
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        flex: 1,
        fontSize: "sm",
        cursor: "pointer",
        paddingInlineStart: 3,
      }}
    />
  )
)

UiRadixCheckboxLabel.displayName = "UiRadixCheckboxLabel"
