// External packages
import * as React from "react"
import { Box, BoxProps, Label, LabelProps } from "theme-ui"

export const UiRadioGroupField: React.FC<BoxProps> = React.forwardRef(
  ({ sx, children, ...rest }, ref) => (
    <Box {...rest} ref={ref} sx={{ ...sx, display: "inline-flex" }}>
      {children}
    </Box>
  )
)

export const UiRadioGroupItem: React.FC<BoxProps> = React.forwardRef(
  ({ sx, children, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      as="button"
      sx={{
        ...sx,
        flexShrink: 0,
        width: 4,
        height: 4,
        border: "1px solid",
        borderColor:
          rest["data-state"] === "checked" ? "primary" : "grayscale.600",
        borderRadius: "9999px",
        backgroundColor:
          rest["data-state"] === "checked" ? "primary" : "transparent",
        cursor: "pointer",
        padding: 0,
        marginBlockStart: "2px",
        transition: "background-color .2s",
      }}
    >
      {children}
    </Box>
  )
)

export const UiRadioGroupLabel: React.FC<LabelProps> = React.forwardRef(
  ({ sx, children, ...rest }, ref) => (
    <Label
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        cursor: "pointer",
        paddingInlineStart: 3,
      }}
    >
      {children}
    </Label>
  )
)
