import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import medusaRequest from "../../services/request"

export interface ProductsBestsellersResponse {
  products_ids: string[]
}

export const useProductsBestsellers = (
  region_id: string,
  options?: UseQueryOptions
) => {
  const { data, ...rest } = useQuery(
    ["bestsellers", region_id],
    async () => {
      const { data }: AxiosResponse<ProductsBestsellersResponse> =
        await medusaRequest("POST", "store/products/bestsellers", {
          region_id,
        })
      return data?.products_ids
    },
    options
  )

  return { data, ...rest } as const
}
