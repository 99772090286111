// External packages
import * as React from "react"
import { Box, BoxProps, Flex, FlexProps, Text } from "theme-ui"
import { keyframes } from "@emotion/react"

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
})

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
})

export const UiAccordionHeader: React.FC<BoxProps> = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <Box {...rest} ref={ref}>
      {children}
    </Box>
  )
)

export const UiAccordionItem: React.FC<BoxProps> = React.forwardRef(
  ({ sx, children, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        borderBlock: "1px solid",
        borderBlockColor: "grayscale.200",
        marginBlockStart: "-1px",
      }}
    >
      {children}
    </Box>
  )
)

type UiAccordionTriggerOwnProps = {
  sideContent?: React.ReactNode
}

export const UiAccordionTrigger: React.FC<
  FlexProps & UiAccordionTriggerOwnProps
> = React.forwardRef(({ sideContent, sx, children, ...rest }, ref) => (
  <Flex
    {...rest}
    ref={ref}
    as="button"
    sx={{
      ...sx,
      color: "primary",
      width: "100%",
      height: 16,
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "sm",
      fontWeight: "normal",
      backgroundColor: "transparent",
      cursor: "pointer",
      border: 0,
      padding: 0,
    }}
  >
    <Box>{children}</Box>
    {Boolean(sideContent) && (
      <Box
        sx={{
          minWidth: 4,
          fontSize: "xs",
          marginLeft: "auto",
          marginRight: 5,
        }}
      >
        {sideContent}
      </Box>
    )}
    <Box sx={{ width: 4 }}>
      {rest["data-state"] === "open" ? (
        <Text sx={{ fontSize: "md" }}>-</Text>
      ) : (
        <Text sx={{ fontSize: "md" }}>+</Text>
      )}
    </Box>
  </Flex>
))

export const UiAccordionContent: React.FC<BoxProps> = React.forwardRef(
  ({ sx, children, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        overflow: "hidden",
        "&[data-state=open]": {
          animation: `${slideDown} 300ms ease-out`,
        },
        "&[data-state=closed]": {
          animation: `${slideUp} 300ms ease-out`,
        },
      }}
    >
      <Box sx={{ paddingBlockEnd: 9 }}>{children}</Box>
    </Box>
  )
)
