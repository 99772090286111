import React from "react"

import Medusa from "../../../services/api"
import { AccountContext } from "../../../context/AccountContext"
import LoadingSpinner from "../../ui/LoadingSpinner"
import { Container, Header, Content, Row } from "./elements"

import IdentifyStep from "./IdentifyStep"
import SignUpStep from "./SignUpStep"

class LoginDrawer extends React.Component {
  state = {
    loading: false,
    header: "",
    step: "identify",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  }

  handleSetHeader = (header) => {
    this.setState({
      header,
    })
  }

  handleVerifyEmail = () => {
    this.setState({
      loading: true,
    })

    return Medusa.auth.exists(this.state.email).then(({ data }) => {
      this.setState({
        loading: false,
        accountExists: data.exists,
        step: data.exists ? "identify" : "signup",
      })
    })
  }

  handleLogin = () => {
    this.setState({
      loading: true,
    })

    this.context
      .logIn(this.state.email, this.state.password)
      .then(() => {
        this.props.hideDrawers()
        // if (window && window.analytics) {
        //   window.analytics.track("User Signed In", {
        //     email: this.state.email,
        //   })
        // }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          authFailed: true,
        })
      })
  }

  handleSignup = () => {
    this.setState({
      loading: false,
      invalidSignupForm: false,
    })

    if (
      !this.state.email ||
      !this.state.password ||
      !this.state.firstName ||
      !this.state.lastName
    ) {
      this.setState({
        loading: false,
        invalidSignupForm: true,
      })
    } else {
      return Medusa.customers
        .create({
          email: this.state.email,
          password: this.state.password,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
        })
        .then(({ data }) => {
          // if (window && window.analytics) {
          //   window.analytics.track("User Signed Up", {
          //     email: this.state.email,
          //     customer: data?.customer?.id ? data.customer.id : null,
          //   })
          // }
          this.context.setUser(data.customer)
          this.setState({
            loading: false,
            step: "welcome",
          })
        })
    }
  }

  handleForgotPassword = () => {
    this.context.forgotPassword(this.state.email).finally(() => {
      this.setState({
        step: "forgot",
      })
    })
  }

  handleFieldChange = (e) => {
    const element = e.target
    this.setState({
      [element.name]: element.value,
    })
  }

  render() {
    return (
      <Container visible={this.props.visible}>
        <Header>{this.state.header}</Header>
        {this.state.loading && <LoadingSpinner />}
        <Content>
          {this.state.step === "forgot" && <div>Reset link sent</div>}
          {this.state.step === "identify" && (
            <IdentifyStep
              onSetHeader={this.handleSetHeader}
              showPassword={this.state.accountExists}
              email={this.state.email}
              password={this.state.password}
              failed={this.state.authFailed}
              onForgot={this.handleForgotPassword}
              onCheckEmail={this.handleVerifyEmail}
              onLogin={this.handleLogin}
              onFieldChange={this.handleFieldChange}
            />
          )}
          {this.state.step === "signup" && (
            <SignUpStep
              isInvalid={this.state.invalidSignupForm}
              className={this.state.signup ? "visible" : "hidden"}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              password={this.state.password}
              onFieldChange={this.handleFieldChange}
              onComplete={this.handleSignup}
              onEnter={this.handleSignup}
            />
          )}
          {this.state.step === "welcome" && (
            <div>
              <Row>
                <h2>{`Welcome, ${this.state.firstName}.`}</h2>
              </Row>
              <Row>Your Tekla account has been activated.</Row>
            </div>
          )}
        </Content>
      </Container>
    )
  }
}

LoginDrawer.contextType = AccountContext

export default LoginDrawer
