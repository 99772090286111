import { isInternalArchive } from "./isInternalArchive"

export const isPurchasable = (variantSku: string, properties: object) => {
  // If the product is an internal archive, check if customer is from staff group
  if (isInternalArchive(variantSku)) {
    return properties?.is_staff
  }

  return true
}
