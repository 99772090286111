import styled from "@emotion/styled"
import Typography from "../../ui/Typography"
import Spacing from "../../ui/Spacing"

export const Header = styled.div`
  top: calc(${props => props.theme.navbar.height});
  left: 10px;

  ${props => props.theme.breakpointsLegacy.tablet} {
    top: 14px;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding-left: 10px;
  padding-right: 95px;
  padding-bottom: 1rem;

  width: 100%;

  position: fixed;

  padding-top: calc(${props => props.theme.navbar.height} + 0.5rem);
  top: 0;
  right: 0;

  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? "1" : "0")};

  transition: visibility 0s, opacity 0.2s linear;
  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  background-color: ${(props) => props.theme.colors.grayscale[100]};
  z-index: 1001;

  ${props => props.theme.breakpointsLegacy.tablet} {
    height: 100%;
    width: 425px;
  }
`

export const Content = styled.div``

export const Row = styled.div`
  ${Spacing};
  width: 100%;
  display: flex;
  align-items: baseline;

  h2 {
    ${Typography.Medium};
  }

  .help-text {
    width: 100%;
    margin-top: 5px;
  }

  .small {
    ${Typography.Small};
  }

  .input {
    width: 100%;
    &:nth-of-type(2) {
      margin-left: 1rem;
    }
  }
`

export const ActionText = styled.span`
  ${Typography.Small}
  cursor: pointer;
  margin-top: 0.5rem;
  float: ${props => (props.left ? "left" : "right")};
  border-bottom: 1px solid ${props => props.theme.colors.grayscale[300]};
  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.grayscale[700]};
  }
`
