import React from "react"
import styled from "@emotion/styled"
import Arrow from "../../assets/svg/arrow-down.svg"

import Spacing from "./Spacing"
import Typography from "./Typography"

const Container = styled.button`
  ${Spacing}
  outline: 0;
  border: none;
  ${(props) =>
    props.secondary &&
    `border-bottom: 1px solid ${props.theme.colors.primary};`}
  ${(props) =>
    props.outline && `border: 1px solid ${props.theme.colors.primary};`}

  ${(props) => (props.large ? Typography.Medium : Typography.Small)}

  line-height: 18px;
  display: ${(props) =>
    props.block === undefined || props.block ? "flex" : "none"};
  align-items: center;
  justify-content: space-between;

  ${(props) => (!props.secondary || props.outline) && `border-radius: 2px`};

  ${(props) => props.margin && `margin: ${props.margin}`};

  width: ${(props) => (props.full ? "100%" : "max-content")};

  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: ${(props) => (props.large ? "10px" : "6px")};
  ${(props) =>
    !props.secondary && `padding-right: ${props.full ? "10px" : "55px"};`}

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? ".6" : "1")};

  height: ${(props) => (props.large ? "28px" : "23px")};
  ${(props) => {
    if (props.disabled) {
      return `background-color: ${props.theme.colors.grayscale[600]};`
    }

    return `background-color: ${
      props.secondary || props.outline
        ? "transparent"
        : props.theme.colors.grayscale[700]
    };`
  }};
  color: ${(props) =>
    props.secondary || props.outline
      ? props.theme.colors.primary
      : props.theme.colors.grayscale.white};

  svg {
    transform: rotate(270deg);
  }

  [stroke*="red"] {
    stroke: ${(props) => props.theme.colors.grayscale.white};
  }

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
          color: ${props.theme.colors.primary};
          ${
            !props.secondary && `background-color: ${props.theme.colors.green};`
          }
          [stroke*="red"] {
            stroke: ${props.theme.colors.primary};
          }
      `}
  }
`

const Button = ({
  children,
  className,
  large,
  disabled,
  arrow,
  full,
  margin,
  block,
  secondary,
  onClick,
  ...rest
}) => {
  return (
    <Container
      onClick={onClick}
      secondary={secondary}
      className={className}
      large={large}
      full={full}
      margin={margin}
      block={block}
      arrow={arrow}
      disabled={disabled}
      {...rest}
    >
      {children}
      {arrow && <Arrow />}
    </Container>
  )
}

export default Button

const SimpleContainer = styled.button`
  ${Spacing}
  outline: 0;
  border: none;
  ${(props) =>
    props.secondary &&
    `border-bottom: 1px solid ${props.theme.colors.primary};`}
  ${(props) =>
    props.outline && `border: 1px solid ${props.theme.colors.primary};`}

${(props) => (props.large ? Typography.Medium : Typography.Small)}

${(props) => (!props.secondary || props.outline) && `border-radius: 2px`};

  ${(props) => props.margin && `margin: ${props.margin}`};

  width: ${(props) => (props.full ? "100%" : "max-content")};

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? ".6" : "1")};

  background-color: ${(props) =>
    props.secondary || props.outline
      ? "transparent"
      : props.theme.colors.grayscale[700]};
  color: ${(props) =>
    props.secondary || props.outline
      ? props.theme.colors.primary
      : props.theme.colors.grayscale.white};

  svg {
    transform: rotate(270deg);
  }

  [stroke*="red"] {
    stroke: ${(props) => props.theme.colors.grayscale.white};
  }

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
        color: ${props.theme.colors.primary};
        ${!props.secondary && `background-color: ${props.theme.colors.green};`}
        [stroke*="red"] {
          stroke: ${props.theme.colors.primary};
        }
    `}
  }
`

export const SimpleButton = ({
  children,
  className,
  large,
  disabled,
  arrow,
  full,
  margin,
  block,
  secondary,
  onClick,
  ...rest
}) => {
  return (
    <SimpleContainer
      onClick={onClick}
      secondary={secondary}
      className={className}
      large={large}
      full={full}
      margin={margin}
      block={block}
      arrow={arrow}
      disabled={disabled}
      {...rest}
    >
      {children}
      {arrow && <Arrow />}
    </SimpleContainer>
  )
}
