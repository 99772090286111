import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import medusaRequest from "../../services/request"

export const useProductsVariantsInventory = (
  variants_ids: string[],
  options?: UseQueryOptions
) => {
  const { data, ...rest } = useQuery(
    ["variants-inventory", variants_ids],
    async () => {
      const { data }: AxiosResponse = await medusaRequest(
        "POST",
        "store/variants/inventory",
        {
          variants: variants_ids,
        }
      )
      return data?.variantsInStock
    },
    options
  )

  return { data, ...rest } as const
}
