import { useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import medusaRequest from "../../services/request"

export interface ProductsPriceRangeResponse {
  products_prices: {
    id: string
    min: number
    max: number
  }[]
}

export const useProductsPriceRange = (
  category_id: string,
  region_id: string,
  currency_code: string
) => {
  const { data, ...rest } = useQuery(
    ["products-price-range", category_id, region_id, currency_code],
    async () => {
      const { data }: AxiosResponse<ProductsPriceRangeResponse> =
        await medusaRequest("POST", "store/products/price-range", {
          category_id,
          region_id,
          currency_code,
        })
      return data?.products_prices
    },
    {
      enabled: Boolean(category_id) && Boolean(region_id),
      keepPreviousData: false,
    }
  )

  return { data, ...rest } as const
}
