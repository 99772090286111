import * as React from "react"
import { Box, BoxProps, Flex, Paragraph } from "theme-ui"

import { Button } from "../Button"
import { Icon } from "./Icon"
import { Input } from "./Input"

export interface CopyToClipboardInputProps extends BoxProps {
  value: string
}

export const CopyToClipboardInput: React.FC<CopyToClipboardInputProps> = ({
  value,
  sx,
  ...boxProps
}) => {
  const [isAddedToClipboard, setIsAddedToClipboard] = React.useState(false)

  React.useEffect(() => {
    if (isAddedToClipboard) {
      setTimeout(() => setIsAddedToClipboard(false), 5000)
    }
  }, [isAddedToClipboard])

  const handleIsAddedToClipboard = () => {
    setIsAddedToClipboard(true)
    navigator.clipboard.writeText(value)
  }

  return (
    <Box sx={{ position: "relative", ...sx }} {...boxProps}>
      <Flex>
        <Input
          placeholder="test placeholder"
          value={value}
          disabled
          sx={{ flex: 1 }}
        />
        <Button
          variant="secondary"
          disabled={isAddedToClipboard}
          onClick={handleIsAddedToClipboard}
          sx={{
            position: "relative",
            color: "grayscale.600",
            height: "auto",
            marginInlineStart: "-1px",
          }}
        >
          <Icon name={isAddedToClipboard ? "checkmark-thin" : "copy"} />
        </Button>
      </Flex>
      <Paragraph
        sx={{
          display: [isAddedToClipboard ? "block" : "none", "block"],
          fontSize: ["xs", "sm"],
          color: "grayscale.600",
          position: [null, "absolute"],
          bottom: [null, -5],
          transform: [null, "translateY(100%)"],
          opacity: [1, isAddedToClipboard ? 1 : 0],
          visibility: ["visible", isAddedToClipboard ? "visible" : "hidden"],
          marginBlockStart: [2, 0],
          transition: "opacity .2s, visibility .2s",
        }}
      >
        The link has been successfully copied!
      </Paragraph>
    </Box>
  )
}
