import * as React from "react"

// External packages
import { Box, BoxProps } from "theme-ui"

export type UiProgressOwnProps = {
  value: number
}

export const UiProgress: React.FC<BoxProps & UiProgressOwnProps> =
  React.forwardRef(({ value = 0, sx, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{ ...sx, height: "2px", backgroundColor: "grayscale.300" }}
    >
      <Box
        sx={{
          width: `${value}%`,
          height: "2px",
          backgroundColor: "grayscale.700",
        }}
      />
    </Box>
  ))
