import * as React from "react"

type UseLockedBodyOutput = [boolean, (lockedMobile: boolean) => void]

export function useLockedBodyMobile(
  initialLocked = false
): UseLockedBodyOutput {
  const [lockedMobile, setLockedMobile] = React.useState(false)

  React.useLayoutEffect(() => {
    if (!lockedMobile) {
      return
    }

    // Save initial values
    const originalScrollPosition = document.documentElement.scrollTop
    const originalOverflow = document.documentElement.style.overflow
    const originalPosition = document.documentElement.style.position
    const originalTop = document.documentElement.style.top
    const originalWidth = document.documentElement.style.width

    // Lock documentElement scroll
    setTimeout(() => {
      document.documentElement.style.overflow = "hidden"
      document.documentElement.style.position = "fixed"
      document.documentElement.style.top = `-${originalScrollPosition}px`
      document.documentElement.style.width = "100%"
    }, 200)

    return () => {
      document.documentElement.style.overflow = originalOverflow
      document.documentElement.style.position = originalPosition
      window.scrollTo(0, originalScrollPosition)
      document.documentElement.style.top = originalTop
      document.documentElement.style.width = originalWidth
    }
  }, [lockedMobile])

  // Update state if initialValue changes
  React.useEffect(() => {
    if (lockedMobile !== initialLocked) {
      setLockedMobile(initialLocked)
    }
  }, [initialLocked])

  return [lockedMobile, setLockedMobile]
}

export default useLockedBodyMobile
