import React, { useContext, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { Box } from "theme-ui"

import { InterfaceContext } from "../../../context/InterfaceContext"
import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import { bottomToTop, fadeIn } from "../shared/keyframes"

export const Background = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11000;
  transition: opacity .2s, visibility .2s;

  .content {
    height: 100%;
    animation: ${fadeIn} 400ms ease-in, ${bottomToTop} 400ms;
  }

  ${(props) =>
    !props.visible &&
    `
      visibility: hidden;
      opacity: 0;
    `};
`

const Modal = () => {
  const { setModalVisible, modalVisible, modalContent } = useContext(
    InterfaceContext
  )

  const [, setScrollable] = useDisableBackgroundScroll(true)
  const containerRef = useRef()

  const handleBackgroundClicked = (e) => {
    if (containerRef.current) {
      if (!containerRef.current.contains(e.target)) {
        setModalVisible(false)
      }
    }
  }

  useEffect(() => {
    if (modalVisible) {
      setScrollable(false)
    } else {
      setScrollable(true)
    }
  }, [modalVisible])

  return (
    <Background visible={modalVisible} onClick={handleBackgroundClicked}>
      <Box className="content" ref={containerRef}>
        {modalContent}
      </Box>
    </Background>
  )
}

export default Modal
