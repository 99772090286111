import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"

import { handleUrlPrefix } from "../../../utils/translations-util"
import { ContentfulTypes } from "../../../utils/types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Text } from "theme-ui"

import {
  ProductNav,
  ScrollContainer,
  Products,
  CategorySection,
  ImageContainer,
} from "./index"
import { InterfaceContext } from "../../../context/InterfaceContext"

const ProductMenuA = ({ items }) => {
  const { hideDrawers, isProductNavOpen } = useContext(InterfaceContext)

  const [active, setActive] = useState(null)
  const [activeImage, setActiveImage] = useState(null)
  const [hovering, setHovering] = useState(false)

  const createCollectionSlug = (baseSlug, collection) => {
    if (collection.fullSlug) {
      return collection.fullSlug
    }
    const collectionSlug = collection.slug
    return handleUrlPrefix("en-US", `l/${baseSlug}/${collectionSlug}`)
  }

  const handleCatClick = (e, cat) => {
    e.preventDefault()
    e.stopPropagation()

    if (cat.collections.length === 1) {
      navigate(
        handleUrlPrefix(
          "en-US",
          `l/${cat.slug}/${cat.collections[0].slug}`
        )
      )
      hideDrawers()
    } else if (active === cat.id) {
      navigate(handleUrlPrefix("en-US", `l/${cat.slug}`))
      hideDrawers()
    } else {
      setActive(cat.id)
    }
  }

  const handleActiveImage = (e, { cat, col }) => {
    e.stopPropagation()
    if (!cat && !col) {
      setActiveImage(null)
      return
    }
    if (cat) {
      cat.collections[0]?.menuImage &&
        setActiveImage(cat.collections[0].menuImage.gatsbyImageData || true)
    } else if (col) {
      col.menuImage && setActiveImage(col.menuImage.gatsbyImageData || true)
    }
  }

  return (
    <ProductNav
      visible={isProductNavOpen}
      activeImage={activeImage}
      active={active}
    >
      <ScrollContainer>
        <Products active={active} hovering={hovering}>
          {items &&
            items.length > 0 &&
            items.map((item) => {
              if (item.internal?.type === ContentfulTypes.ContentfulCategory) {
                const cat = item
                return (
                  <CategorySection key={item.id} active={item.id === active}>
                    <span
                      onClick={(e) => handleCatClick(e, cat)}
                      className="category-item link"
                      onMouseOver={(e) => {
                        setHovering(true)
                        handleActiveImage(e, { cat: cat })
                      }}
                      onMouseLeave={(e) => {
                        setHovering(false)
                        handleActiveImage(e, {})
                      }}
                    >
                      <div>{cat.name}</div>
                    </span>
                    <div
                      className={
                        active === cat.id ? "collections active" : "collections"
                      }
                    >
                      {cat.collections.length >= 1 &&
                        cat.collections.map((collection) => {
                          let fullSlug = createCollectionSlug(
                            cat.slug,
                            collection
                          )

                          return (
                            <Link
                              onClick={hideDrawers}
                              key={collection.id}
                              className="collection-item"
                              to={fullSlug}
                              onMouseOver={(e) => {
                                setHovering(true)
                                handleActiveImage(e, { col: collection })
                              }}
                              onMouseLeave={(e) => {
                                setHovering(false)
                                handleActiveImage(e, {})
                              }}
                            >
                              {collection.name}
                            </Link>
                          )
                        })}
                    </div>
                  </CategorySection>
                )
              }

              if (item.internal?.type === ContentfulTypes.ContentfulPage) {
                return (
                  <CategorySection key={item.id} active={item.id === active}>
                    <Link
                      to={ "/" + item.slug?.replace(/^\//, "")}
                      onClick={hideDrawers}
                      className="category-item link"
                      onMouseOver={(e) => {
                        setHovering(true)
                        handleActiveImage(e, {})
                      }}
                      onMouseLeave={(e) => {
                        setHovering(false)
                        handleActiveImage(e, {})
                      }}
                    >
                      <Text>{item.pageTitle}</Text>
                    </Link>
                  </CategorySection>
                )
              }
            })}
          <CategorySection>
            <Link
              to={"/all-products"}
              onClick={hideDrawers}
              className="category-item link"
              onMouseOver={(e) => {
                setHovering(true)
                handleActiveImage(e, {})
              }}
              onMouseLeave={(e) => {
                setHovering(false)
                handleActiveImage(e, {})
              }}
            >
              <Text>All products</Text>
            </Link>
          </CategorySection>
        </Products>
      </ScrollContainer>
      {activeImage && (
        <ImageContainer>
          <GatsbyImage style={{ height: "100%" }} image={activeImage} alt="" />
        </ImageContainer>
      )}
    </ProductNav>
  )
}

export default ProductMenuA
