import React, { useState, useEffect, useRef } from "react"
import { Flex } from "@theme-ui/components"
import styled from "@emotion/styled"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { Icon } from "../../v2/ui/Icon"
import { Box } from "theme-ui"
import { useStore } from "../../../context/NewStoreContext"

const PromotionalContainer = styled(Flex)`
  height: 34px;
  align-items: center;
  padding-inline: 10px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.grayscale[100]};
  z-index: 8501;
  position: relative;

  ${(props) => props.theme.breakpointsLegacy.mobile} {
    padding-inline: 40px;
  }

  .fade {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
`

const PromotionalText = styled.div`
  font-size: 14px;
  flex: 1;
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${(props) => props.theme.breakpointsLegacy.mobile} {
    font-size: 16px;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }
`

const PromotionalElement = React.forwardRef(({ input }, ref) => {
  const { body } = input
  let { carouselText } = input
  const store = useStore()

  if (
    carouselText?.length &&
    store.cart?.id &&
    carouselText?.some(
      (item) => item?.body?.raw?.indexOf("Free shipping for orders above") > -1
    )
  ) {
    const startDate = new Date(2023, 11, 11)
    let endDate = new Date("2023-12-16T11:00:00.000Z")
    const today = new Date()

    if (store.cart.region_id === "5f4cd45b5d1e3200214c0e49") {
      endDate = new Date("2023-12-20T11:00:00.000Z")
    }

    if (
      [
        "reg_01GJFE28F90P1K7CWB2FTC81AP",
        "reg_01FW1G272PCNGEHRVHJTPBAX8J",
        "reg_01FDY1G4NA3MRWP7Z68YMXEP9C",
        "reg_01F21Y3YVHTPP9VPYBX0ZMB9B3",
        "5f7dc29885872c002141a53c",
        "5f7dc1e585872c002141a535",
        "5f7dc19e85872c002141a52e",
        "5f4cd9435d1e3200214c0e83",
        "5f4cd59d5d1e3200214c0e4f",
        "5f4cd57a5d1e3200214c0e4e",
      ].includes(store.cart.region_id)
    ) {
      endDate = new Date("2023-12-15T11:00:00.000Z")
    }

    if (today >= startDate && today <= endDate) {
      const textsToReplace = carouselText.filter(
        (text) => text.body.raw.indexOf("Free shipping for orders above") > -1
      )

      if (textsToReplace?.length) {
        carouselText = carouselText.filter(
          (text) =>
            text.body.raw.indexOf("Free shipping for orders above") === -1
        )

        textsToReplace.forEach((text) => {
          let textBody = JSON.parse(text.body.raw)

          textBody.content[0].content[0].value = "Free shipping on all orders"
          text.body.raw = JSON.stringify(textBody)
        })
        carouselText = [...carouselText, ...textsToReplace]
      }
    }
  }

  const myRef = useRef(null)
  const [activePromotionalText, setActivePromotionalText] = useState()
  const { isPromotionalVisible, setIsPromotionalVisible } =
    useInterfaceContext()

  useEffect(() => {
    if (sessionStorage.getItem("promotionalElement::visible")) {
      setIsPromotionalVisible(false)
    }
  }, [])

  useEffect(() => {
    if (!isPromotionalVisible) {
      sessionStorage.setItem("promotionalElement::visible", false)
    }
  }, [isPromotionalVisible])

  useEffect(() => {
    myRef.current.classList.add("fade")
    carouselText?.length && setActivePromotionalText(carouselText[0])

    if (carouselText?.length) {
      const bannerInterval = setInterval(() => {
        myRef?.current?.classList?.remove("fade")

        setTimeout(() => {
          setActivePromotionalText((prev) => {
            let prevActive = carouselText.indexOf(prev) + 1
            prevActive === carouselText.length && (prevActive = 0)

            return carouselText[
              prevActive === carouselText.length ? 0 : prevActive
            ]
          })
        }, 200)

        setTimeout(() => {
          myRef?.current?.classList?.add("fade")
        }, 225)
      }, 4425)
      return () => {
        clearInterval(bannerInterval)
      }
    }
  }, [carouselText])

  if (!input) {
    return (
      <PromotionalContainer
        sx={{
          justifyContent: ["center", "flex-end"],
          bg: "grayscale.white",
          marginBottom: "6px",
          color: "primary",
        }}
        ref={ref}
      >
        here
      </PromotionalContainer>
    )
  }

  return (
    <PromotionalContainer
      sx={{
        justifyContent: ["center"],
        bg: "grayscale.white",
        marginBottom: "6px",
        color: "primary",
        display: !isPromotionalVisible && "none !important",
      }}
      ref={ref}
    >
      <PromotionalText ref={myRef} className="fade">
        {carouselText?.length
          ? activePromotionalText?.body
            ? renderRichText(activePromotionalText.body)
            : ""
          : body
          ? renderRichText(body)
          : ""}
      </PromotionalText>
      <Box
        sx={{
          position: ["initial", "absolute"],
          right: [0, 3],
          cursor: "pointer",
          marginRight: 1,
          marginLeft: 3,
        }}
        onClick={() => setIsPromotionalVisible(false)}
      >
        <Icon name="x" size="5" sx={{ color: "grayscale.700" }} />
      </Box>
    </PromotionalContainer>
  )
})

PromotionalElement.displayName = "PromotionalElement"

export default PromotionalElement
