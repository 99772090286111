const productSizeSortOrder = [
  "one size",
  "xxs",
  "xtra xtra small",
  "extra extra small",
  "xs",
  "xtra small",
  "extra small",
  "s",
  "small",
  "m",
  "medium",
  "l",
  "large",
  "xl",
  "xtra large",
  "extra large",
  "xxl",
  "2xl",
  "xtra xtra large",
  "extra extra large",
  "xxxl",
  "3xl",
  "xtra xtra xtra large",
  "extra extra extra large",
  "xxxxl",
  "4xl",
  "xtra xtra xtra xtra large",
  "extra extra extra extra large",
  "xxxxxl",
  "5xl",
  "xtra xtra xtra xtra xtra large",
  "extra extra extra extra extra large",
]

export const sortSizes = (sizes) => {
  return sizes.sort((a, b) => {
    const aIndex = productSizeSortOrder.indexOf(a.label.toLowerCase())
    const bIndex = productSizeSortOrder.indexOf(b.label.toLowerCase())

    if (aIndex === -1 && bIndex === -1) {
      return a.label.localeCompare(b.label)
    }

    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    return aIndex - bIndex
  })
}
