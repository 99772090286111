import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import { handleUrlPrefix } from "../../../utils/translations-util"
import { ContentfulTypes } from "../../../utils/types"
import {
  CategorySection,
  ProductNav,
  Products,
  ScrollContainer,
  RegionSelection,
} from "./index"
import { InterfaceContext } from "../../../context/InterfaceContext"
import { useStore } from "../../../context/NewStoreContext"
import { Flex, Text } from "theme-ui"
import BackIcon from "../../../assets/svg/down-arrow-small.svg"

const MobileProductMenu = ({ items }) => {
  const [active, setActive] = useState(null)
  const [, setScrollable] = useDisableBackgroundScroll()

  const { hideDrawers, isProductNavOpen, showRegion } =
    useContext(InterfaceContext)

  const { cart } = useStore()

  useEffect(() => {
    if (isProductNavOpen) {
      setScrollable(false)
    } else {
      setScrollable(true)
    }
  }, [isProductNavOpen, setScrollable])

  /*
    Creates the collection slug based on the products.
    If it only contains one product, return the specific product slug,
    otherwise return collection list slug
  */
  const createCollectionSlug = (baseSlug, collection) => {
    if (collection.fullSlug) {
      return collection.fullSlug
    }
    const collectionSlug = collection.slug
    return handleUrlPrefix("en-US", `l/${baseSlug}/${collectionSlug}`)
  }

  const handleCatClick = (e, cat) => {
    e.preventDefault()
    e.stopPropagation()

    if (cat.collections.length === 1) {
      navigate(
        handleUrlPrefix("en-US", `l/${cat.slug}/${cat.collections[0].slug}`)
      )
      hideDrawers()
    } else if (active === cat.id) {
      navigate(handleUrlPrefix("en-US", `l/${cat.slug}`))
      hideDrawers()
    } else {
      setActive(cat.id)
    }
  }

  return (
    <ProductNav visible={isProductNavOpen}>
      <ScrollContainer>
        <Products>
          <CategorySection>
            <Link
              to="/l/all-products"
              onClick={hideDrawers}
              className="category-item link"
            >
              <Text>All products</Text>
            </Link>
          </CategorySection>
          {items &&
            items.length > 0 &&
            items.map((item) => {
              if (item.internal?.type === ContentfulTypes.ContentfulCategory) {
                const cat = item
                return (
                  <CategorySection key={cat.id} active={cat.id === active}>
                    <span
                      onClick={(e) => handleCatClick(e, cat)}
                      className="category-item link"
                    >
                      <div>{cat.name}</div>
                    </span>
                    <div
                      className={
                        active === cat.id ? "collections active" : "collections"
                      }
                    >
                      <Flex
                        sx={{ alignItems: "center" }}
                        className="collection-item"
                        onClick={() => setActive(null)}
                      >
                        <BackIcon className="back-icon" />
                        <Text sx={{ marginLeft: "16px", fontWeight: "bold" }}>
                          {cat.name}
                        </Text>
                      </Flex>
                      {cat.collections.length >= 1 &&
                        cat.collections.map((collection) => {
                          let fullSlug = createCollectionSlug(
                            cat.slug,
                            collection
                          )

                          return (
                            <Link
                              onClick={hideDrawers}
                              key={collection.id}
                              className="collection-item"
                              to={fullSlug}
                            >
                              {collection.name}
                            </Link>
                          )
                        })}
                    </div>
                  </CategorySection>
                )
              }

              if (item.internal?.type === ContentfulTypes.ContentfulPage) {
                return (
                  <CategorySection key={item.id} active={item.id === active}>
                    <Link
                      to={"/" + item.slug?.replace(/^\//, "")}
                      onClick={hideDrawers}
                      className="category-item link"
                    >
                      <Text>{item.pageTitle}</Text>
                    </Link>
                  </CategorySection>
                )
              }
            })}
          <CategorySection>
            <Link
              to={handleUrlPrefix("en-US", "projects")}
              onClick={hideDrawers}
              className="category-item link"
            >
              <Text>Projects</Text>
            </Link>
          </CategorySection>
        </Products>

        <RegionSelection onClick={showRegion}>
          {cart?.shipping_address?.country_code &&
            cart?.region?.currency_code && (
              <>
                Country & Currency:{" "}
                <Text sx={{ display: "inline-block", fontWeight: "bold" }}>
                  {cart?.shipping_address.country_code.toUpperCase()}
                  &#8201;/&#8201;
                  {cart?.region.currency_code.toUpperCase()}
                </Text>
              </>
            )}
        </RegionSelection>
      </ScrollContainer>
    </ProductNav>
  )
}

export default MobileProductMenu
