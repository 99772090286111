export const getTypePlural = (type: string) => {
  if (!type) return type
  const exceptions = [
    "Boxers",
    "Pants",
    "Shorts",
    "Socks",
    "Mohair",
    "Cashmere",
    "Pure wool",
    "Fine merino",
    "Gingham",
    "Lambswool",
    "Cashmere and lambswool",
    "Mohair and pure wool",
  ]
  if (exceptions.includes(type)) {
    return type
  }
  if (type.endsWith("s")) {
    return type
  }
  if (type.endsWith("y")) {
    return type.slice(0, -1) + "ies"
  }
  return type + "s"
}
