// External packages
import * as React from "react"
import {
  Grid,
  Box,
  GridProps,
  BoxProps,
  Paragraph,
  Heading,
  Flex,
} from "theme-ui"
import { ProductCategory } from "@medusajs/medusa"
import GatsbyLink from "gatsby-link"
import { PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Shopstory } from "@shopstory/core/react"
import { useLocation } from "@reach/router"
import {
  CategoryPageProduct,
  CategoryPageWidget,
  WidgetProduct,
} from "gatsby-node"

// Contexts
import { TeklaShopstoryProvider } from "../../../src/shopstory/Provider"
import { useStore } from "../../context/NewStoreContext"
import { useAccountContext } from "../../context/AccountContext"

// Utilities
import { formatMoneyAmount } from "../../utils/prices"
import {
  isContentfulWidgetDrawer,
  isContentfulWidgetLink,
  isContentfulWidgetShopTheLook,
} from "../../components/v2/contentful/utils"

// Hooks
import useLockedBody from "../../hooks/v2/useLockedBody"
import { useProductsPriceRange } from "../../hooks/v2/useProductsPriceRange"

// Components
import Layout from "../../components/layouts/layout"
import { Link, LinkProps } from "../../components/v2/Link"
import { Button, ButtonProps } from "../../components/v2/Button"
import { Icon } from "../../components/v2/ui/Icon"
import { Drawer } from "../../components/v2/ui/Drawer"
import SEO from "../../components/seo"
import { FeaturedProductsDrawer } from "../../components/v2/FeaturedProductsDrawer"
import {
  UiButtonFolded,
  UiButtonFoldedProps,
} from "../../components/v2/ui/ButtonFolded"
import { WidgetDrawer } from "../../components/v2/contentful/WidgetDrawer"
import { WidgetLink } from "../../components/v2/contentful/WidgetLink"
import { WidgetShopTheLook } from "../../components/v2/contentful/WidgetShopTheLook"
import { Picture } from "../../components/v2/Picture"

interface CategoryLandingPageTemplateProps {
  category: Pick<
    ProductCategory,
    | "id"
    | "handle"
    | "name"
    | "parent_category"
    | "category_children"
    | "description"
    | "metadata"
  >
  products: CategoryPageProduct[]
  widgets: CategoryPageWidget[]
  seo: {
    canonicalUrl: string
    description: string
    metaImageUrl: string
    breadcrumbsList: {
      "@type": string
      position: number
      name: string
      item: string
    }[]
  }
  shopstoryContent: any | undefined
}

type Data =
  | ({
      variant: "product"
      priceMin: number | undefined
      priceMax: number | undefined
    } & CategoryPageProduct)
  | ({ variant: "widget" } & CategoryPageWidget)

type Product = CategoryPageProduct & {
  priceMin: number
  priceMax: number
}

const CategoryLandingPageTemplate = ({
  pageContext,
}: PageProps<object, CategoryLandingPageTemplateProps>) => {
  const { category, products, widgets, seo } = pageContext
  const categoryGridRef = React.useRef(null)
  const { cart } = useStore()
  const location = useLocation()
  const { properties } = useAccountContext()

  // Get price range
  const { data: prices, refetch: refetchPrices } = useProductsPriceRange(
    category.id,
    cart?.region_id,
    cart?.region?.currency_code
  )

  // Exclude internal archive products for logged out user
  const purchasableProducts = React.useMemo(() => {
    if (!prices) {
      return products
    }

    const filteredProducts =
      cart?.region.name === "South Korea" // Exclude Gift card for South Korea
        ? products.filter((p) => p.sku !== "T-GC")
        : products
    return filteredProducts.map((p) => {
      return {
        ...p,
        priceMin: prices?.find((price) => price.id === p.id)?.min,
        priceMax: prices?.find((price) => price.id === p.id)?.max,
      }
    })
  }, [products, properties, prices])

  // Set grid data
  const gridData = () => {
    let data: Data[] = []

    data = purchasableProducts?.map((product, i) => {
      return {
        ...product,
        variant: "product",
        image: product.image,
        imageOnHover: product.imageOnHover,
      }
    })

    if (widgets?.length > 0) {
      const sortedWidgets = widgets.sort(
        (a, b) => a.displayAfterNthItem - b.displayAfterNthItem
      )
      sortedWidgets.forEach((widget) => {
        if (data.length >= widget.displayAfterNthItem) {
          data.splice(widget.displayAfterNthItem, 0, {
            ...widget,
            variant: "widget",
          })
        }
      })
    }
    return data
  }

  const getRows = (element: HTMLElement[]) => {
    const tolerance = 1
    const rows = new Array()

    // Group elements by their bottom position
    element.forEach((element) => {
      // Get the bottom position of the element
      const bottom = element.offsetTop + element.offsetHeight
      // Find a row with the same bottom position
      const sameRowArray = rows.find((row) => {
        const rowBottom = row[0].offsetTop + row[0].offsetHeight
        return Math.floor(Math.abs(rowBottom - bottom)) <= tolerance
      })

      // If a row with the same bottom position exists and element is not a
      // widget, add the element to that row, otherwise create a new row
      if (element.dataset.textual !== "true") {
        if (rows.length > 0 && Boolean(sameRowArray)) {
          sameRowArray.push(element)
        } else {
          rows.push([element])
        }
      }
    })

    return rows
  }

  const pageTitle = category.name || ""
  const data = React.useMemo(() => gridData(), [purchasableProducts, widgets])
  const numOfWidgets = data?.filter((item) => item.variant === "widget")?.length

  const [openedDrawerId, setOpenedDrawerId] = React.useState<
    CategoryPageWidget["contentful_id"] | Product["id"] | null
  >(null)
  const [isFeaturedProductsDrawerOpened, setIsFeaturedProductsDrawerOpened] =
    React.useState<boolean>(false)
  const [featuredProductsDrawerProducts, setFeaturedProductsDrawerProducts] =
    React.useState<WidgetProduct[]>([])
  const [
    isFeaturedProductsSizeDrawerOpened,
    setIsFeaturedProductsSizeDrawerOpened,
  ] = React.useState<boolean>(false)
  const [locked, setLocked] = useLockedBody(false)

  React.useEffect(() => {
    if (cart?.region_id) {
      refetchPrices()
    }
  }, [cart?.region_id])

  React.useEffect(() => {
    // Get elements grouped by their bottom position
    const rows = getRows(Array.from(categoryGridRef.current.children))

    const handleResize = () => {
      // Set the height of each image element to the highest footer height in the row
      rows.forEach((row) => {
        const highestFooterHeight = Math.max(
          ...row.map((element: HTMLElement) => {
            const footerElement = element.querySelector(
              "[data-footer]"
            ) as HTMLElement
            if (Boolean(footerElement) === false) return
            return footerElement.offsetHeight
          })
        )

        row.forEach((item: HTMLElement) => {
          const imageElement = item.querySelector("[data-image]") as HTMLElement
          if (Boolean(imageElement)) {
            imageElement.style.height = `calc(100% - ${
              highestFooterHeight + "px"
            })`
          }
        })
      })
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [data, prices])

  const subCategories =
    category?.category_children?.filter(
      (cat) => cat.is_active && !cat.is_internal
    ) ||
    category?.parent_category?.category_children?.filter(
      (cat) => cat.is_active && !cat.is_internal
    ) ||
    []

  return (
    <Layout almostWhite>
      <SEO
        title={pageTitle}
        metaImage={seo.metaImageUrl && seo.metaImageUrl}
        description={seo.description}
        canonicalUrl={seo.canonicalUrl}
        breadcrumbsList={seo.breadcrumbsList}
      />
      <Box sx={{ paddingBlockStart: [3, 21], paddingBlockEnd: [8, 18] }}>
        <Box sx={{ paddingInline: 4, marginBlockEnd: 8 }}>
          <Heading as="h1" sx={{ display: "inline", fontSize: "xl" }}>
            {pageTitle}
          </Heading>
          {category.description && (
            <Heading as="h2" sx={{ display: "inline", fontSize: "xl" }}>
              &nbsp;– {category.description}
            </Heading>
          )}
        </Box>

        {subCategories.length > 1 ? (
          <Box
            sx={{
              overflowX: ["scroll", "unset"],
              borderBlock: ["1px solid", "none"],
              borderBlockColor: ["grayscale.300", "transparent"],
              scrollbarWidth: "none",
              paddingBlock: [5, 0],
              marginInline: [0, 4],
              marginBlockStart: 7,
              marginBlockEnd: 9,
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Flex sx={{ fontSize: "sm", gap: 6, alignItems: "center" }}>
              {subCategories.map((cat, index) => (
                <>
                  <Box
                    key={cat.name}
                    sx={{
                      flexShrink: 0,
                      paddingInlineStart: index === 0 ? [4, 0] : null,
                      paddingInlineEnd:
                        index + 1 === subCategories.length ? [4, 0] : null,
                    }}
                  >
                    <Link
                      to={
                        cat.handle === "all-gifts"
                          ? "/gift-shop"
                          : category?.parent_category
                          ? `/${category.parent_category.handle}/${cat.handle}`
                          : `/${category.handle}/${cat.handle}`
                      }
                      sx={{
                        borderBlockEnd: "1px solid",
                        borderBlockEndColor:
                          cat.handle === location.pathname.split("/").at(-1) ||
                          (location.pathname.split("/").length < 3 &&
                            cat.rank === 0)
                            ? "grayscale.700"
                            : "transparent",
                      }}
                    >
                      {cat.name === "All gifts"
                        ? "Back to all gifts"
                        : cat.name}
                    </Link>
                  </Box>
                  {index === 0 ? (
                    <Box
                      sx={{
                        flexShrink: 0,
                        width: "1px",
                        height: 3,
                        backgroundColor: "primary",
                        marginInline: 3,
                      }}
                    />
                  ) : null}
                </>
              ))}
            </Flex>
          </Box>
        ) : null}
        <UiCategoryGrid ref={categoryGridRef}>
          {data?.length > 0 &&
            data.map((item) => {
              if (item.variant === "widget") {
                if (isContentfulWidgetDrawer(item)) {
                  return (
                    <WidgetDrawer
                      key={item.contentful_id}
                      data={item}
                      isInCategoryTemplate
                    />
                  )
                }
                if (isContentfulWidgetLink(item)) {
                  return (
                    <WidgetLink
                      key={item.contentful_id}
                      data={item}
                      isInCategoryTemplate
                    />
                  )
                }
                if (isContentfulWidgetShopTheLook(item)) {
                  return (
                    <WidgetShopTheLook
                      key={item.contentful_id}
                      data={item}
                      isInCategoryTemplate
                    />
                  )
                }

                // Logic for "old" ContentfulWidget, keep this until all the widgets are migrated in Contentuful to one of 3 new types
                if (
                  item.products?.length > 0 &&
                  item.background?.file?.contentType?.startsWith("image")
                ) {
                  const buttonPosition = item.buttonPosition ? "start" : "end"
                  const buttonColor = item.buttonColor ? "light" : "dark"
                  const buttonVariant = item.buttonVariant
                    ? "folded"
                    : "unfolded"

                  const alignmentInGrid = item.alignmentInGrid ? "start" : "end"

                  return (
                    <FeaturedProductsWidget
                      title={item.title}
                      image={item.background.gatsbyImageData}
                      align={alignmentInGrid}
                      size={item.size === "2x2" ? "large" : "default"}
                      isFullWidthOnMobile={Boolean(item.fullWidthOnMobile)}
                      buttonPosition={buttonPosition}
                      buttonFoldedProps={
                        buttonVariant === "folded" && {
                          children: item.buttonLabel,
                          variant:
                            buttonColor === "light" ? "secondary" : "primary",
                        }
                      }
                      buttonUnfoldedProps={
                        buttonVariant === "unfolded" && {
                          children: item.buttonLabel,
                          variant:
                            buttonColor === "light" ? "secondary" : "primary",
                        }
                      }
                      onClick={() => {
                        setLocked(true)
                        setIsFeaturedProductsDrawerOpened(true)
                        setFeaturedProductsDrawerProducts(item.products)
                      }}
                    />
                  )
                }

                if (item.background?.file?.contentType?.startsWith("image")) {
                  return (
                    <VisualItem
                      key={item.contentful_id}
                      title={item.title}
                      handle={item.url}
                      image={item.background.gatsbyImageData}
                      size={
                        item.size === "1x1"
                          ? "default"
                          : item.size === "2x2"
                          ? "large"
                          : null
                      }
                    />
                  )
                }

                if (item.background?.file?.contentType?.startsWith("video")) {
                  return (
                    <VisualItem
                      key={item.contentful_id}
                      title={item.title}
                      handle={item.url}
                      video={item.background.file.url}
                      size={
                        item.size === "1x1"
                          ? "default"
                          : item.size === "2x2"
                          ? "large"
                          : null
                      }
                    />
                  )
                }

                return (
                  <TextualItem
                    key={item.contentful_id}
                    title={item.title}
                    titleFontSize={item.titleFontSize}
                    description={item.description}
                    drawerContent={item.drawerContent}
                    buttonLabel={item.buttonLabel}
                    contentful_id={item.contentful_id}
                    size={
                      item.size === "1x1"
                        ? "default"
                        : item.size === "2x2"
                        ? "large"
                        : null
                    }
                    handle={item.url}
                    setOpenedDrawerId={setOpenedDrawerId}
                    setLocked={setLocked}
                  />
                )
              }
              return (
                <VisualItem
                  key={item.id}
                  title={item.title}
                  handle={item.handle}
                  image={item.image}
                  imageOnHover={item.imageOnHover}
                  priceMin={item.priceMin}
                  priceMax={item.priceMax}
                />
              )
            })}
        </UiCategoryGrid>
        {category?.metadata?.page_button_label &&
          category?.metadata?.page_button_url && (
            <Box sx={{ textAlign: "center", marginBlockStart: [8, 14] }}>
              <Button
                to={category?.metadata?.page_button_url as string}
                variant="ghost"
              >
                {category?.metadata?.page_button_label as string}
              </Button>
            </Box>
          )}
      </Box>
      {numOfWidgets > 0 &&
        widgets.map((widget) => {
          if (
            Boolean(widget.drawerContent) &&
            Boolean(widget.compiledDrawerContent)
          ) {
            return (
              <Drawer
                key={widget.contentful_id}
                isOpened={openedDrawerId === widget.contentful_id}
                size="xl"
                onCloseClick={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
                onBackdropClick={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
                onSwipeRight={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
              >
                <ShopstoryModule
                  shopstoryContent={widget.compiledDrawerContent}
                />
              </Drawer>
            )
          } else if (widget.products?.length > 0) {
            return (
              <FeaturedProductsDrawer
                isOpened={isFeaturedProductsDrawerOpened}
                key={widget.contentful_id}
                products={featuredProductsDrawerProducts}
                isFeaturedProductsSizeDrawerOpened={
                  isFeaturedProductsSizeDrawerOpened
                }
                setIsFeaturedProductsSizeDrawerOpened={
                  setIsFeaturedProductsSizeDrawerOpened
                }
                setLocked={setLocked}
                onCloseClick={() => {
                  setIsFeaturedProductsDrawerOpened(false)
                  setLocked(false)
                }}
                onBackdropClick={() => {
                  setIsFeaturedProductsDrawerOpened(false)
                  setIsFeaturedProductsSizeDrawerOpened(false)
                  setLocked(false)
                }}
              />
            )
          }
        })}
    </Layout>
  )
}

export const UiCategoryGrid: React.FC<GridProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Grid
      {...rest}
      ref={ref}
      sx={{
        gridAutoFlow: "dense",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
        gridTemplateRows: "1fr",
        columnGap: 4,
        rowGap: 8,
        paddingInline: 4,
        ...sx,
      }}
    />
  )
)

type VisualItemProps = LinkProps & {
  handle: string
  image?: IGatsbyImageData
  imageOnHover?: IGatsbyImageData
  video?: string
  title: string
  size?: "default" | "large"
  priceMin?: number
  priceMax?: number
  selectedSize?: string
  wrapperClassName?: string
  titleClassName?: string
  priceClassName?: string
}

export const VisualItem: React.FC<VisualItemProps> = ({
  handle,
  image,
  imageOnHover,
  video,
  title,
  size,
  priceMin,
  priceMax,
  selectedSize,
  wrapperClassName,
  titleClassName,
  priceClassName,
}) => {
  const { cart } = useStore()
  return (
    <Box
      className={wrapperClassName}
      sx={{
        gridColumn: size === "large" && "1 / span 2",
        gridRow: size === "large" && "span 2",
        "> a": {
          color: "primary",
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          "&:hover .hover-image": {
            opacity: [0, 1],
            visibility: ["hidden", "visible"],
          },
        },
      }}
    >
      <GatsbyLink
        to={handle}
        state={{ size: selectedSize, scrollTo: { x: 0, y: 0 } }}
      >
        <Box
          data-image
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "4 / 5",
          }}
        >
          {image ? (
            <Picture
              sources={image?.images.sources}
              aspectRatio="vertical"
              sx={{ height: "100%" }}
              imageProps={{
                width: image?.width,
                height: image?.height,
                sizes: image?.images?.fallback?.sizes,
                src: image?.images?.fallback?.src,
                srcSet: image?.images?.fallback?.srcSet,
                alt: title,
              }}
            />
          ) : null}
          {video ? (
            <Box
              sx={{
                height: "100%",
                video: {
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}
            >
              <video src={video} autoPlay muted={true} loop playsInline>
                <p>
                  Your browser doesn't support HTML video. Here is a
                  <a href={video}>link to the video</a> instead.
                </p>
              </video>
            </Box>
          ) : null}
          {Boolean(imageOnHover) && (
            <Picture
              className="hover-image"
              sources={imageOnHover.images.sources}
              aspectRatio="vertical"
              sx={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                visibility: "hidden",
              }}
              imageProps={{
                width: imageOnHover.width,
                height: imageOnHover.height,
                sizes: imageOnHover.images.fallback.sizes,
                src: imageOnHover.images.fallback.src,
                srcSet: imageOnHover.images.fallback.srcSet,
                alt: title,
              }}
            />
          )}
        </Box>
        <Box data-footer>
          {Boolean(title) && (
            <Paragraph
              sx={{
                fontSize: "sm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingBlockStart: 2,
              }}
              className={titleClassName}
            >
              {title}
            </Paragraph>
          )}
          {Boolean(priceMin) && Boolean(priceMax) && (
            <Paragraph
              sx={{ fontSize: "sm", marginBlockStart: 1 }}
              className={priceClassName}
            >
              {priceMin === priceMax ? (
                formatMoneyAmount({
                  amount: priceMin,
                  currencyCode: cart?.region?.currency_code,
                })
              ) : (
                <>
                  {formatMoneyAmount({
                    amount: priceMin,
                    currencyCode: cart?.region?.currency_code,
                  })}{" "}
                  -{" "}
                  {formatMoneyAmount({
                    amount: priceMax,
                    currencyCode: cart?.region?.currency_code,
                  })}
                </>
              )}
            </Paragraph>
          )}
        </Box>
      </GatsbyLink>
    </Box>
  )
}

type FeaturedProductsWidgetOwnProps = {
  title: string
  image?: IGatsbyImageData
  imageOnHover?: IGatsbyImageData
  video?: string
  align?: "start" | "end"
  isFullWidthOnMobile?: boolean
  buttonFoldedProps?: UiButtonFoldedProps
  buttonUnfoldedProps?: ButtonProps
  buttonPosition?: "start" | "end"
  hideButton?: boolean
  size?: "default" | "large"
  handle?: string
}

export const FeaturedProductsWidget: React.FC<
  BoxProps & FeaturedProductsWidgetOwnProps
> = ({
  title,
  image,
  align = "start",
  isFullWidthOnMobile,
  imageOnHover,
  video,
  buttonFoldedProps,
  buttonUnfoldedProps,
  buttonPosition = "start",
  hideButton = false,
  handle,
  size,
  ...rest
}) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <Box
      {...rest}
      sx={{
        position: "relative",
        gridColumn:
          size === "large"
            ? align === "start"
              ? "1 / span 2"
              : align === "end"
              ? ["1 / span 2", "3 / span 2"]
              : null
            : null,
        gridRow: size === "large" ? "span 2" : null,
        cursor: "pointer",
        marginInline: isFullWidthOnMobile && [-4, 0],
        "> a": {
          color: "primary",
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          height: "100%",
          "&:hover .hover-image": {
            opacity: [0, 1],
            visibility: ["hidden", "visible"],
          },
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <GatsbyLink to={handle} state={{ scrollTo: { x: 0, y: 0 } }}>
        <Box
          data-image
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "4 / 5",
            "> button": {
              height: "100%",
              padding: 0,
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            },
          }}
        >
          {image ? (
            <Picture
              sources={image?.images.sources}
              aspectRatio="vertical"
              sx={{ height: "100%" }}
              imageProps={{
                width: image?.width,
                height: image?.height,
                sizes: image?.images?.fallback?.sizes,
                src: image?.images?.fallback?.src,
                srcSet: image?.images?.fallback?.srcSet,
                alt: title,
              }}
            />
          ) : null}
          {video ? (
            <Box
              sx={{
                height: "100%",
                video: {
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}
            >
              <video src={video} autoPlay muted={true} loop playsInline>
                <p>
                  Your browser doesn't support HTML video. Here is a
                  <a href={video}>link to the video</a> instead.
                </p>
              </video>
            </Box>
          ) : null}
          {Boolean(imageOnHover) && (
            <Picture
              className="hover-image"
              sources={imageOnHover.images.sources}
              aspectRatio="vertical"
              sx={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                visibility: "hidden",
              }}
              imageProps={{
                width: imageOnHover.width,
                height: imageOnHover.height,
                sizes: imageOnHover.images.fallback.sizes,
                src: imageOnHover.images.fallback.src,
                srcSet: imageOnHover.images.fallback.srcSet,
                alt: title,
              }}
            />
          )}
          {hideButton ? null : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                padding: 4,
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  position: "sticky",
                  bottom: 4,
                  justifyContent: buttonPosition === "end" ? "end" : "start",
                  marginBlockStart: "auto",
                }}
              >
                {Boolean(buttonFoldedProps) ? (
                  <UiButtonFolded
                    {...buttonFoldedProps}
                    isFolded={!isHovered}
                  />
                ) : (
                  <Button
                    {...buttonUnfoldedProps}
                    iconRight={
                      <Icon
                        name="plus"
                        size="13px"
                        sx={{
                          "svg line": {
                            strokeWidth: "1px",
                          },
                        }}
                      />
                    }
                  />
                )}
              </Flex>
            </Box>
          )}
        </Box>
      </GatsbyLink>
      <Box data-footer>
        {Boolean(title) && (
          <Paragraph
            sx={{
              fontSize: "sm",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              paddingBlockStart: 2,
            }}
          >
            {title}
          </Paragraph>
        )}
      </Box>
    </Box>
  )
}

type TextualItemProps = BoxProps & {
  title: CategoryPageWidget["title"]
  titleFontSize: CategoryPageWidget["titleFontSize"]
  description: CategoryPageWidget["description"]
  drawerContent: CategoryPageWidget["drawerContent"]
  buttonLabel: CategoryPageWidget["buttonLabel"]
  handle: CategoryPageWidget["url"]
  contentful_id: CategoryPageWidget["contentful_id"]
  size?: "default" | "large"
  setOpenedDrawerId: (value: CategoryPageWidget["contentful_id"] | null) => void
  setLocked: (value: boolean) => void
}

export const TextualItem: React.FC<TextualItemProps> = React.forwardRef(
  (
    {
      title,
      titleFontSize,
      description,
      buttonLabel,
      drawerContent,
      handle,
      contentful_id,
      size,
      setOpenedDrawerId,
      setLocked,
      ...rest
    },
    ref
  ) => (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        height: "100%",
        gap: [4, 5],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        // gridColumn:
        //   size === "large" && align === "start"
        //     ? "1 / span 2"
        //     : size === "large" && align === "end"
        //     ? "3 / span 2"
        //     : null,
        gridColumn: size === "large" && "1 / span 2",
        gridRow: size === "large" && "span 2",
      }}
      data-textual
    >
      <Heading
        sx={{
          fontSize:
            titleFontSize === null
              ? "xl"
              : [`calc(${titleFontSize} - 4px)`, titleFontSize],
        }}
      >
        {title}
      </Heading>
      <Flex sx={{ flexDirection: "column", gap: 3, fontSize: "sm" }}>
        <Paragraph>{description}</Paragraph>
      </Flex>
      {Boolean(drawerContent) ? (
        <Button
          onClick={() => {
            setOpenedDrawerId(contentful_id)
            setLocked(true)
          }}
          variant="ghost"
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button variant="ghost" to={handle}>
          {buttonLabel}
        </Button>
      )}
    </Flex>
  )
)

export const ShopstoryModule = ({ shopstoryContent }) => {
  return (
    <TeklaShopstoryProvider>
      <Shopstory {...shopstoryContent} />
    </TeklaShopstoryProvider>
  )
}

export default CategoryLandingPageTemplate
