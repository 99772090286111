import React from "react"
import { Flex, Text } from "theme-ui"
import Button from "../button"
import { navigate } from "gatsby"
import { useStore } from "../../../context/NewStoreContext"

const NoStoreModal = ({ afterSubmit }) => {
  const { createCartWithRegion } = useStore()

  const submit = () => {
    afterSubmit()
  }
  return (
    <Flex sx={{ maxWidth: "311px", p: 1, flexDirection: "column" }}>
      <Text sx={{ fontSize: "21px", marginBottom: "16px" }}>Sorry!</Text>
      <Text sx={{ fontSize: "13px", marginBottom: "20px" }}>
        It appears you are in a country we don’t currently ship to. You can
        still browse our collections on the international page, although you
        won’t be able to purchase anything.
      </Text>
      <Button
        variant="light"
        sx={{ marginBottom: "8px" }}
        onClick={() => {
          createCartWithRegion("5f4cd9435d1e3200214c0e83", "HK")
          submit()
        }}
      >
        Browse our collections
      </Button>
      <Button
        onClick={() => {
          submit()
          createCartWithRegion("5f4cd9435d1e3200214c0e83", "HK")
          navigate("/about")
        }}
      >
        See our stockists
      </Button>
    </Flex>
  )
}

export default NoStoreModal
