export const sortByRank = <T extends { metadata?: Record<string, any> }>(
  items: T[],
  key: string
): T[] => {
  if (!items?.length || !key) {
    return items
  }

  return items.sort((a, b) => {
    const aRank = a.metadata?.[`${key}_rank`] || 0
    const bRank = b.metadata?.[`${key}_rank`] || 0

    return (aRank as number) - (bRank as number)
  })
}