import React from "react"
import styled from "@emotion/styled"

export const MobileOnly = styled.div`
  width: 100%;
  display: block;

  .section-title {
    font-size: 18px;
    margin-top: 20px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: none;
  }
`
