import styled from "@emotion/styled"
import React from "react"
import Input from "../../ui/Input"
import { Row } from "./elements"
import Button from "../../ui/Button"

const Container = styled.div`
  width: 100%;
`

const SignUpStep = ({
  isInvalid,
  firstName,
  lastName,
  password,
  onComplete,
  onFieldChange,
}) => {
  return (
    <Container>
      <Row>
        <h2>Welcome to Tekla</h2>
      </Row>
      <Row pt="1rem">
        <div className="small">
          Please fill in the details below to complete your account
        </div>
      </Row>
      {isInvalid && (
        <Row pt="1rem">
          <div className="small danger">
            Sorry – you must fill in all fields below
          </div>
        </Row>
      )}
      <Row>
        <Input
          className="input"
          maxLength="50"
          placeholder="First Name"
          name="firstName"
          value={firstName}
          onChange={onFieldChange}
        />
        <Input
          className="input"
          maxLength="50"
          placeholder="Last Name"
          name="lastName"
          value={lastName}
          onChange={onFieldChange}
        />
      </Row>
      <Row>
        <Input
          password
          className="input"
          maxLength="50"
          placeholder="Password"
          name="password"
          value={password}
          onChange={onFieldChange}
        />
      </Row>
      <Row pt="2rem">
        <Button onClick={onComplete}>Complete</Button>
      </Row>
    </Container>
  )
}

export default SignUpStep
