import styled from "@emotion/styled"
import mq from "../../utils/mq"
import Typography from "../ui/Typography"

export const CartBag = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.wishlistCount ? `0.7rem` : `1rem`)};
  cursor: pointer;

  .hide-on-mobile {
    display: none;
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      display: block;
    }
  }

  .circle {
    transition: width 200ms, height 200ms;
    margin-left: 0.8rem;
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: calc(49% + 1px);
    margin-top: 2px;
    &.not-empty {
      padding-bottom: 1px;
      width: 19px;
      height: 19px;
    }
    &.not-empty.gt-10 {
      width: 24px;
      border-radius: 12px;
    }
    &.empty {
      width: 10px;
      height: 10px;
    }
  }

  span {
    ${Typography.Small}
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-left: 1px;
  }
`

export const NavItem = styled.div`
  ${Typography.Medium};
  position: relative;
  cursor: pointer;
  display: flex;
  justify-self: ${(props) => props.justifySelf};

  ${(props) =>
    props.header &&
    `
    visibility: hidden;
    flex: 1;
    padding-left: 25px;
    ${props.theme.breakpointsLegacy.tablet} {
      visibility: visible;
    }
  `}

  .hide-on-mobile {
    display: none;
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      display: block;
    }
  }

  ${(props) =>
    props.hideOnMobile
      ? mq({
          display: ["none", "none", "block", "block"],
        })
      : ``}
`
