// External packages
import * as React from "react"
import { Box, BoxProps } from "theme-ui"

/**
 * UiSlider
 */
export const UiSlider: React.FC<BoxProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{ ...sx, position: "relative", marginBlockStart: "6px" }}
    />
  )
)

UiSlider.displayName = "UiSlider"

/**
 * UiSliderTrack
 */
export const UiSliderTrack: React.FC<BoxProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        position: "relative",
        height: "1px",
        backgroundColor: "grayscale.200",
      }}
    />
  )
)

UiSliderTrack.displayName = "UiSliderTrack"

/**
 * UiSliderRange
 */
export const UiSliderRange: React.FC<BoxProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "primary",
      }}
    />
  )
)

UiSliderRange.displayName = "UiSliderRange"

/**
 * UiSliderThumb
 */
export const UiSliderThumb: React.FC<BoxProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...sx,
        width: 3,
        height: 3,
        backgroundColor: "primary",
        borderRadius: "12px",
        cursor: "pointer",
        transform: "translateY(-50%)",
        "&:focus-visible": {
          outline: "none",
        },
      }}
    />
  )
)

UiSliderThumb.displayName = "UiSliderThumb"
