import * as React from "react"

import NotificationContext, {
  useNotificationContext,
} from "../../../context/NotificationContext"

import { Notification } from "../ui/Notification"

const NotificationContainer = () => {
  const { dismissNotification } = useNotificationContext()
  return (
    <NotificationContext.Consumer>
      {({ notifications }) => {
        return notifications.map((n, index) => (
          <Notification
            key={index}
            onCloseClick={() => dismissNotification(n.id)}
          >
            {n.desktopBody || n.body}
          </Notification>
        ))
      }}
    </NotificationContext.Consumer>
  )
}

export default NotificationContainer
