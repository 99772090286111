import * as React from "react"
import { Box, BoxProps } from "theme-ui"

import { Link } from "../Link"
import { Icon } from "./Icon"

export interface NotificationProps extends BoxProps {
  onCloseClick?: () => void
}

export const Notification: React.FC<NotificationProps> = ({
  children,
  sx,
  onCloseClick,
  ...boxProps
}) => {
  return (
    <Box
      {...boxProps}
      sx={{
        fontSize: "sm",
        color: "grayscale.700",
        width: ["100%", 110],
        position: "fixed",
        top: [0, 5],
        right: [0, 5],
        backgroundColor: "grayscale.white",
        boxShadow: "0px 0px 18px -4px rgba(24, 24, 24, 0.1)",
        zIndex: "notification",
        paddingBlockStart: 6,
        paddingBlockEnd: 7,
        paddingInline: [4, 8],
      }}
    >
      {children}
      <Link
        sx={{ color: "grayscale.700", position: "absolute", top: 5, right: 5 }}
      >
        <Icon name="x" size={5} onClick={() => onCloseClick()} />
      </Link>
    </Box>
  )
}
