// External packages
import * as React from "react"
import styled from "@emotion/styled"
import { Box, Flex, Text, Paragraph } from "theme-ui"

// Services
import Medusa from "../../services/api"

// Utilities
import { countryLookup } from "../../utils/countries"

// Components
import { MobileOnly } from "../ui/ConditionRender"
import { Icon } from "../v2/ui/Icon"

const RegionContainer = styled(Flex)`
  position: fixed;
  height: 100%;
  bottom: 0;
  right: 0;

  width: 100%;
  flex-direction: column;

  padding: 0 10px;
  padding-bottom: 1rem;
  padding-top: calc(30px);

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  transition: visibility 0s, opacity 0.2s linear;
  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  background-color: ${(props) => props.theme.colors.grayscale[100]};
  z-index: ${(props) => props.theme.zIndices.regionDrawer};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 420px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Header = styled(Flex)`
  display: flex;
  justify-content: space-between;
  .header {
    flex: 1;
  }
  margin-bottom: 48px;
`

const CountriesContainer = styled(Flex)`
  display: flex;
  flex: 1;
  overflow: hidden scroll;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.grayscale[100]};

  .country-container {
    h2 {
      font-size: 19px;
      font-weight: 300;
    }
  }

  .bar-icon {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    flex-direction: row;
    padding: 10px;
    padding-left: 4%;

    .icon {
      align-self: center;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    border: none;

    flex-basis: 100%;

    .bar-icon {
      padding-left: 35px;
    }

    .country-container {
      padding-left: 34px;
      margin: 12px 0;
      h2 {
        font-size: 19px;
        font-weight: 300;
      }
    }
  }
`

const Countries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  font-size: 15px;
  max-height: 100%;
  overflow: auto;
  margin-bottom: 8px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    font-size: 18px;
  }
`

const Country = styled.button`
  color: ${(props) => props.theme.colors.primary};
  appearance: none;
  border: 0;
  background-color: transparent;
  text-align: left;
  line-height: 1.25;
  cursor: pointer;
  margin-bottom: 18px;
  padding: 0;

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-bottom: 14px;
  }
`

const Info = styled(Flex)`
  display: none;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    bottom: 0;
    padding-bottom: 20px;
    display: flex;
    flex: 1;
    padding-top: 20px;
    padding-left: 34px;
  }
`

const RegionDrawer = ({
  visible,
  onSetCountry,
  toggleRegionOpen,
  promotionalVisible,
}) => {
  const [countries, setCountries] = React.useState(() => {
    const defaultCountries = {
      allCountries: [],
      visibleCountries: [],
    }

    return defaultCountries
  })

  React.useEffect(() => {
    Medusa.regions
      .list()
      .then(({ data }) => {
        let countries = data.regions.map((region) => {
          return region.countries.map((country) => {
            const countryDisplayName = countryLookup(
              country.iso_2.toUpperCase()
            )

            return {
              name: countryDisplayName,
              country_code: country.iso_2.toUpperCase(),
              currency_code: region.currency_code,
              region: region.name,
              region_id: region.id,
            }
          })
        })

        return countries.flat().sort((a, b) => sortByName(a, b))
      })
      .then((countries) => {
        setCountries({ allCountries: countries, visibleCountries: countries })
      })
  }, [])

  const sortByName = (a, b) => {
    if (a.name > b.name) {
      return 1
    } else if (b.name > a.name) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <RegionContainer visible={visible} promotionalVisible={promotionalVisible}>
      <Box
        sx={{ position: "absolute", top: 4, right: 4, cursor: "pointer" }}
        onClick={() => toggleRegionOpen()}
      >
        <Icon name="x" size="5" sx={{ color: "grayscale.700" }} />
      </Box>
      <MobileOnly>
        <Header>
          <Text sx={{ fontSize: "21px" }}>Country & Currency</Text>
        </Header>
      </MobileOnly>
      <CountriesContainer>
        <div className="country-container">
          <Paragraph sx={{ fontSize: "19px", marginBlockEnd: 5 }}>
            Choose Location
          </Paragraph>
          <Countries>
            {countries.allCountries &&
              countries.visibleCountries.map((country) => {
                return (
                  <Country
                    key={country.name}
                    onClick={() => onSetCountry(country)}
                  >
                    {country.name}
                  </Country>
                )
              })}
          </Countries>
        </div>
      </CountriesContainer>
      <Info>
        Choose your shipping location in order to see correct pricing, delivery
        times & shipping costs.
      </Info>
    </RegionContainer>
  )
}

export default RegionDrawer
