import React from "react"
import styled from "@emotion/styled"

import Typography from "./Typography"
import Spacing from "./Spacing"

const InputFieldContainer = styled.div`
  ${Typography.Base};
  ${Spacing};
  margin: ${(props) => props.margin};
  position: relative;
  padding-top: 18px;

  margin-top: 10px;
  label {
    position: absolute;
    ${Typography.Small};
    color: ${(props) => props.theme.colors.primary};
    left: 1px;
    top: 0;
  }

  input {
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : ``)}
    border-radius: 2px;
    border: 1px solid ${(props) => props.theme.colors.grayscale[600]};
    background-color: transparent;

    padding-left: 5px;

    color: ${(props) => props.theme.colors.primary};

    &::placeholder {
      color: ${(props) => props.theme.colors.grayscale[700]};
    }

    height: 30px;
    width: 100%;

    &:disabled {
      border-color: lightgray;
    }

    &:focus {
      outline: none !important;
      background-color: white !important;
    }
  }

  input.validation-fail {
    border-color: ${(props) => props.theme.colors.red};
  }

  .err {
    ${Typography.Small}
    font-style: italic;
    margin: 5px 0px;
  }

  ${(props) =>
    props.hasError &&
    `
    input {
      border-color: ${props.theme.colors.red};
    }
  `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-left: 0;
  }
`

const InputField = ({
  autoFocus,
  disabled,
  maxWidth,
  maxLength,
  placeholder,
  label,
  name,
  password,
  refFunc,
  value,
  onChange,
  onBlur,
  onKeyPress,
  className,
  error,
  ...rest
}) => (
  <InputFieldContainer
    hasError={!!error}
    className={className}
    maxWidth={maxWidth}
    {...rest}
  >
    {label && <label>{label}</label>}
    {error && <div className="err">{error}</div>}
    <input
      ref={refFunc}
      autoFocus={autoFocus}
      disabled={disabled}
      maxLength={maxLength}
      type={password ? "password" : "text"}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      value={value || ""}
    />
  </InputFieldContainer>
)

export default InputField
